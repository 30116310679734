

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";
import { CompanyInfo } from "../../../models/super-admin/company-details.model";

@Component
export default class CompanyDetails extends Vue {
  public companyInfo: any = new CompanyInfo();
  public profilePic: any = null;
  public logo: any = null;
  // public id = this.companyInfo.id;

  public async getCompanyDetail() {
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/company-details/getCompanyDetails`,
        {
          headers: authHeader(),
        }
      );
      if (response.data.length > 0) this.companyInfo = response.data[0];
    } catch (error) {
      console.log(error);
    }
  }

  public async addCompanyDetails() {
    try {
       this.callLoader(true,null)
       if(!this.companyInfo.cellphone && !this.companyInfo.workPhone){
         await this.callLoader(false, null);
         return;
       }
      const response = await Axios.post(
        `${BASE_API_URL}super-admin/company-details/addCompanyDetails`,
        { ...this.companyInfo },
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.$snotify.success("Company Details has been updated");
      }
    } catch (error) {
      console.log(error);
    }
     this.callLoader(false,null)
  }

   public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }


  // public async uploadFile(event) {
  //   try {
  //     if (event.target.files.length <= 0) return;
  //     const formData: any = new FormData();
  //     Array.from(event.target.files).forEach((element) => {
  //       formData.append("file", element);
  //     });
  //     formData.append("userId", this.companyInfo._id);
  //     const response = await Axios.post(
  //       BASE_API_URL + "super-admin/company-details/uploadLogo",
  //       formData,
  //       { headers: authHeader() }
  //     );
  //     this.getLogo();
  //     this.$snotify.success("logo updated");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // public async getLogo() {
  //   try {
  //     const response = await Axios.get(
  //       BASE_API_URL + "super-admin/company-details/getLogo",
  //       { headers: authHeader() }
  //     );
  //     if (response.status === 201) this.logo = response.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  mounted() {
    this.getCompanyDetail();
    // this.getLogo();
  }
}
