var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"page-content-wrapper"}},[_c('div',{staticClass:"white-card admin-height-85 mb_15"},[_vm._m(0),_c('hr',{staticClass:"loan-process-border-top mt-0 mb-0"}),_c('div',{staticClass:"col-12 pt-3 pb-3"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addCompanyDetails)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"col-md-12 "},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Company Name"}},[_vm._v("Company Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.name),expression:"companyInfo.name"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Company Name","placeholder":"Company Name"},domProps:{"value":(_vm.companyInfo.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "name", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"TIN"}},[_vm._v("TIN")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.tin),expression:"companyInfo.tin"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"TIN","placeholder":"TIN","maxlength":"10"},domProps:{"value":(_vm.companyInfo.tin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "tin", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.email),expression:"companyInfo.email"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Email","placeholder":"Email"},domProps:{"value":(_vm.companyInfo.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "email", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"length:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Cell Phone"}},[_vm._v("Cell Phone")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.companyInfo.cellphone),expression:"companyInfo.cellphone"}],staticClass:"login-input",attrs:{"type":"text","id":"Cell Phone","placeholder":"Cell Phone"},domProps:{"value":(_vm.companyInfo.cellphone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "cellphone", $event.target.value)}}}),(!_vm.companyInfo.cellphone && !_vm.companyInfo.workPhone)?_c('span',{staticClass:"login-error show"},[_vm._v("The Cell Phone field is required")]):_vm._e(),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"length:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Work Phone"}},[_vm._v("Work Phone")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.companyInfo.workPhone),expression:"companyInfo.workPhone"}],staticClass:"login-input",attrs:{"type":"text","id":"Work Phone","placeholder":"Work Phone"},domProps:{"value":(_vm.companyInfo.workPhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "workPhone", $event.target.value)}}}),(!_vm.companyInfo.cellphone && !_vm.companyInfo.workPhone)?_c('span',{staticClass:"login-error show"},[_vm._v("The Work Phone field is required")]):_vm._e(),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-lg-3 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Phone Ext"}},[_vm._v("Phone Ext")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.workPhoneExt),expression:"companyInfo.workPhoneExt"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Phone Ext","placeholder":"Phone Ext"},domProps:{"value":(_vm.companyInfo.workPhoneExt)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "workPhoneExt", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|length:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Fax"}},[_vm._v("Fax")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('###-###-####'),expression:"'###-###-####'"},{name:"model",rawName:"v-model",value:(_vm.companyInfo.faxNumber),expression:"companyInfo.faxNumber"}],staticClass:"login-input",attrs:{"type":"text","id":"Fax","placeholder":"Fax"},domProps:{"value":(_vm.companyInfo.faxNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "faxNumber", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Fax"}},[_vm._v("Company NMLS")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.companyNMLS),expression:"companyInfo.companyNMLS"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Company NMLS","placeholder":"Company NMLS"},domProps:{"value":(_vm.companyInfo.companyNMLS)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "companyNMLS", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Fax"}},[_vm._v("Individual NMLS")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.individualNMLS),expression:"companyInfo.individualNMLS"}],staticClass:"login-input",attrs:{"type":"text","placeholder":"Individual NMLS","id":"Individual NMLS"},domProps:{"value":(_vm.companyInfo.individualNMLS)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo, "individualNMLS", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)])])])]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{},[_c('p',{staticClass:"mb-2 mt-3 heading"},[_vm._v("Address")])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Street"}},[_vm._v("Street")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.street),expression:"companyInfo.address.street"}],staticClass:"login-input",attrs:{"type":"text","id":"Street","placeholder":"Street"},domProps:{"value":(_vm.companyInfo.address.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "street", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Unit"}},[_vm._v("Unit")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.unit),expression:"companyInfo.address.unit"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Unit","placeholder":"Unit"},domProps:{"value":(_vm.companyInfo.address.unit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "unit", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"City"}},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.city),expression:"companyInfo.address.city"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"City","placeholder":"City"},domProps:{"value":(_vm.companyInfo.address.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "city", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"State"}},[_vm._v("State")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.state),expression:"companyInfo.address.state"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"State","placeholder":"State"},domProps:{"value":(_vm.companyInfo.address.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "state", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|min:5|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Zip"}},[_vm._v("Zip")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.zip),expression:"companyInfo.address.zip"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Zip","placeholder":"Zip"},domProps:{"value":(_vm.companyInfo.address.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "zip", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"Country"}},[_vm._v("Country")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.address.country),expression:"companyInfo.address.country"}],staticClass:"login-input line_height_40",attrs:{"type":"text","id":"Country","placeholder":"Country"},domProps:{"value":(_vm.companyInfo.address.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyInfo.address, "country", $event.target.value)}}}),(errors)?_c('span',{staticClass:"login-error show"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1)]),_c('hr',{staticClass:"loan-process-border-top"}),_c('div',{staticClass:"d-flex justify-content-end align-items-center mt-2"},[_c('button',{staticClass:"tmpco-btn tmpco-btn__theme"},[_vm._v(_vm._s(_vm.$store.state.tmpcoLoader ? 'Updating...' : 'Update'))])])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 d-flex justify-content-between align-items-center mb-2 pt-3"},[_c('p',{staticClass:"heading mb-2 mt-1"},[_vm._v("Company Details")])])}]

export { render, staticRenderFns }